<template>
    <div class="kt-widget5">
        <perfect-scrollbar style="max-height: 100vh; position: relative;">
            <template v-for="(item, i) in datasrc">
                <div class="kt-widget5__item" :key="`item-${i}`"  v-bind:class="{'disabled': item.scheduled_delete }">
                    <div class="kt-widget5__content">
                        <div class="kt-widget5__pic">
                            <img
                                class="kt-widget7__img"
                                :src="item.image"
                                alt=""
                            />
                        </div>
                        <div class="kt-widget5__section">
                            <h5>{{item.title}}</h5>
                            <a href="#" class="kt-widget5__title">
                                {{ item.plan }}
                            </a>
                            <div class="kt-widget-5__info" v-if="!item.scheduled_delete">
                                <span
                                ><router-link :to="{ name: 'manage-websites', params: { domainname: item.domain_name }}"> <span class="badge badge-primary kt-font">Manage</span></router-link> &nbsp; 
                                <router-link  :to="'builder/' + item.domain_name"><span class="badge badge-info kt-font">Builder</span> </router-link>&nbsp;
                                <router-link  :to="'settings/' + item.domain_name"><span class="badge badge-success  kt-font">Settings</span></router-link> &nbsp;
                                <router-link  :to="'preview/' + item.domain_name"><span class="badge badge-warning kt-font">Preview</span></router-link>
                                </span>
                          
                           </div>
                            <p class="kt-widget5__desc">
                                    <span class="kt-info" v-if="item.custom_domain && !item.scheduled_delete"> <i class="fa fa-globe kt-font-info"></i></span> &nbsp;
                                    <span class="kt-info" v-if="item.upgrade_eligible && !item.scheduled_delete"> <i class="fa fa-arrow-up kt-font-info"></i></span> &nbsp;
                                    <span class="kt-info" v-if="item.ssl && !item.scheduled_delete"> <i class="fab fa-expeditedssl kt-font-success"></i></span> &nbsp;
                                    <span class="kt-info" v-if="item.users && !item.scheduled_delete"> <i class="fa fa-users kt-font-info"></i></span> &nbsp;
                                    <span class="kt-info"> <i class="fa fa-trash kt-font-info"></i></span> &nbsp;
                            </p>
                        </div>
                    </div>
                    <div class="kt-widget5__content">
                        <div class="kt-widget5__stats" v-if="item.page">
                            <span class="kt-widget5__number">{{item.page_total}}</span>
                            <span class="kt-widget5__sales">ages</span>
                        </div>
                        <div class="kt-widget5__stats" v-if="item.product">
                                <span class="kt-widget5__number">{{item.product_total}}</span>
                                <span class="kt-widget5__sales">products</span>
                        </div>
                        <div class="kt-widget5__stats" v-if="item.blog">
                                <span class="kt-widget5__number">{{item.blog_total}}</span>
                                <span class="kt-widget5__votes">blogs</span>
                        </div>
                    </div>
                </div>
            </template>
        </perfect-scrollbar>
    </div>
</template>

<script>
export default {
    name: "website-widget-5",
    components: {},
    props: {
        datasrc: Array
    }
};
</script>
<style scoped>
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__stats{
    padding-right: 3rem;
}
.kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__stats:last-child{
    padding-right: 0rem;
}
.kt-widget5__item.disabled {
    opacity: 0.5;
}
</style>
