<template>
  <div>
    <h3>Websites</h3>
    <div class="row">

      <div v-if="!loading" class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-for="(item,index) in websites "
           :key="index">
        <!--begin:: Portlet-->
        <div class="kt-portlet kt-portlet--height-fluid">
          <div class="kt-portlet__body kt-portlet__body--fit">
            <!--begin::Widget -->
            <div class="kt-widget kt-widget--project-1">
              <div class="kt-widget__head d-flex">
                <div class="kt-widget__label">
                  <div class="kt-widget__media kt-widget__media--m">
                    <!--                                    <span class="kt-media kt-media&#45;&#45;md kt-media kt-hidden-">-->
                    <!--                                        <i class="flaticon flaticon2-website"-->
                    <!--                                           style="font-size:36px; display: flex;"></i>-->
                    <!--                                    </span>-->
                    <!--                    <span class="kt-media kt-media&#45;&#45;md kt-media&#45;&#45;circle kt-hidden">-->
                    <!--                                        <img src="assets/media/users/100_12.jpg" alt="image"/>-->
                    <!--                                    </span>-->
                  </div>
                  <div class="kt-widget__info kt-padding-0 ">
                    <router-link class="kt-widget__title"
                                 :to="{ name: 'manage-websites', params: { domainname: (item.domain_name && item.is_custom_domain)?item.domain_name:item.sub_domain_name }}">
                      {{
                        (item.domain_name &&
                            item.is_custom_domain) ? item.domain_name : item.sub_domain_name
                      }}
                    </router-link>

                    <span class="kt-widget__desc">{{ item.plan }}</span>
                  </div>
                </div>
                <div class="kt-widget__toolbar">
                  <a href="#" class="btn btn-clean btn-sm btn-icon btn-icon-md"
                     data-toggle="dropdown">
                    <i class="flaticon-more-1"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                    <ul class="kt-nav">

                      <li class="kt-nav__item">
                        <a @click="restoreWebsite(item.domain_name ? item.domain_name:  item.sub_domain_name)"
                           target="_blank"
                           class="kt-nav__link">
                          <i class="kt-nav__link-icon flaticon-globe"></i>
                          <span class="kt-nav__link-text"> Restore Website</span>
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
              <div class="kt-widget__body">
                <div class="kt-widget__stats">
                  <div class="kt-widget__item d-flex align-items-center">
                    <div class="kt-widget__label">
                      <!--                      <pre>{{item}}</pre>-->
                      <span class="btn btn-label-brand btn-sm btn-bold btn-upper kt-margin-r-10" v-if="!item.plan_id">Free Plan</span>
                      <span class="btn btn-label-brand btn-sm btn-bold btn-upper kt-margin-r-10"
                            v-else>{{ item.subscription.title | '' }}</span>
                    </div>
                    <div class="kt-widget__label" v-if="item.is_custom_domain">

                      <span class="btn btn-label-warning btn-sm btn-bold btn-upper kt-margin-r-10">Custom Domain</span>
                    </div>
                    <div class="kt-widget__label" v-if="item.has_ssl || !item.is_custom_domain">
                      <span class="btn btn-label-success btn-sm btn-bold btn-upper kt-margin-r-10">SSL Secured</span>
                    </div>
                    <div class="kt-widget__label" v-if="item.setup_steps<5">
                      <span class="btn btn-label-warning btn-sm btn-bold btn-upper kt-margin-r-10">Incomplete</span>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-2">
                    <span class="kt-widget__subtitel">Storage</span>
                  </div>
                  <div class="col-md-7">
                    <b-progress v-if="item.volume_used" :variant="'success'"
                                :value="item.volume_used.percentage_used"
                                show-progress class="mb-3"></b-progress>
                    <b-progress v-else :value="value" :variant="'success'" show-progress
                                class="mb-3"></b-progress>
                  </div>
                  <div class="col-md-3">
                    <strong v-if="item.volume_used">{{
                        item.volume_used.percentage_used.toFixed(2)
                      }} %</strong>
                    <strong v-else>0%</strong>
                  </div>
                </div>

                <div class="kt-widget__stats kt-margin-t-5">
                  <div class="kt-widget__item d-flex align-items-center kt-padding-l-0">
                    <span class="kt-widget__date kt-padding-0 kt-margin-r-10"> Expiry</span>
                    <div class="kt-widget__label">
                      <span
                          class="btn btn-label-info btn-sm btn-bold btn-upper">{{
                          item.plan_expiry_date ? formatDate(item.plan_expiry_date) : 'No Plan Available'
                        }}</span>
                    </div>
                  </div>
                  <div class="kt-widget__item d-flex align-items-center kt-padding-l-0">
                    <span class="kt-widget__date kt-padding-0 kt-margin-r-10"> </span>
                    <div class="kt-widget__label">
                      <span
                          class="btn btn-label-info btn-sm btn-bold btn-upper">Website Not Setup Completly</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kt-widget__footer">
                <div class="kt-widget__wrapper">
                  <div class="kt-widget__section">
                    <div class="kt-widget__blog">
                      <i class="flaticon2-list-1"></i>
                      <a href="#" class="kt-widget__value kt-font-brand">{{
                          item.total_page ?
                              item.total_page : 0
                        }} Pages</a>
                    </div>

                    <div class="kt-widget__blog pl-2">
                      <i class="flaticon2-talk"></i>
                      <a href="#" class="kt-widget__value kt-font-brand">{{
                          item.total_blog ?
                              item.total_blog : 0
                        }} Blogs</a>
                    </div>
                    <!-- <div class="kt-widget__blog pl-2">
                          <i class="flaticon2-list-1"></i>
                          <a href="#" class="kt-widget__value kt-font-brand">{{ item.total_product ? item.total_product : 0 }} Products</a>
                        </div> -->
                  </div>
                </div>
              </div>
            </div>

            <!--end::Widget -->
          </div>
        </div>

        <!--end:: Portlet-->
      </div>
      <div v-if="loading" class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-for="(item,index) in 4 " :key="index">
        <div class="container">
          <v-skeleton-loader v-if="loading"
                             type="list-item-avatar, article,list-item, actions"></v-skeleton-loader>
        </div>
      </div>
      <div class="col-xl-4 col-md-4 col-sm-12">
        <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower">
          <div class="kt-portlet__body">
            <div class="kt-iconbox__body">
              <div class="kt-iconbox__icon">
                <i class="flaticon2-ui kt-font-success kt-font-big" style="font-size:60px"></i>
              </div>
              <div class="kt-iconbox__desc">
                <h3 class="kt-iconbox__title">
                  <a :href="createWebsiteUrl" class="kt-link">Build a
                    Website
                  </a>
                </h3>
                <div class="kt-iconbox__content">
                  <br/>Got a new business ? Make a website today
                  <hr/>
                  <div class="v-card__actions pull-right">
                    <button type="button"
                            class="btn btn btn-primary btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right btn-secondary">

                      <a :href="createWebsiteUrl" class="v-btn__content" target="_blank">
                        Create Website
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-col sm="12" md="12" lg="12">
        <div class="text-right">
          <v-pagination
              v-model="currentPage"
              :length="total"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              @input="getWebsites()"
          ></v-pagination>
        </div>
      </v-col>
      <!-- <div class="col-xl-4 col-md-4 col-sm-12">
            <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower">
              <div class="kt-portlet__body">
                <div class="kt-iconbox__body">
                  <div class="kt-iconbox__icon">
                    <i class="fa fa-user-tie kt-font-success kt-font-big" style="font-size:60px"></i>
                  </div>
                  <div class="kt-iconbox__desc">
                    <h3 class="kt-iconbox__title">
                      <a class="kt-link" href="#">Hire an Expert</a>
                    </h3>
                    <div class="kt-iconbox__content">
                      <br />Looking for help ? Contact us today
                      <hr />
                      <div class="v-card__actions pull-right">
                        <button
                          type="button"
                          class="btn btn btn-primary btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right btn-secondary"
                        >
                          <span class="v-btn__content">Contact us</span>
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ContextMenu1 from "@/views/partials/widgets/ContextMenu1.vue";
import WebsiteListWidget from "@/views/partials/widgets/WebsiteList.vue";
import Website from "@/services/Websites/WebsiteService";
/**
 * Sample widgets data source
 */
import websiteList from "@/common/mock/website_user_list.json";
import moment from 'moment';
const websiteService = new Website();

export default {
  name: "dashboard",
  components: {
    KTPortlet,
    ContextMenu1,
    WebsiteListWidget
  },
  data() {
    return {
      // timelines: timelines,
      loading: true,
      widget5: websiteList,
      tabIndex: 0,
      tabIndex2: 0,
      websites: [],
      value: 0,
      createWebsiteUrl: '',
      total: 1,
      currentPage: 1,
      perPage: 0,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Websites "
    }]);
    this.getWebsites();
    this.createWebsiteUrl = process.env.VUE_APP_SERVER_URL + 'templates';
  },

  methods: {
    domainUrl(item) {
      return item.domain_name ? item.domain_name : item.sub_domain_name
    },
    formatDate(date) {
      return moment(date).format("MMM DD YYYY");
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */



    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    getWebsites() {
      this.loading = true;
      websiteService.trashed(this.currentPage).then(res => {
        this.websites = res.data.data;
        this.loading = false;
        this.perPage = res.data.meta.per_page;
        this.total = res.data.meta.last_page;
      }).finally(this.loading = false);
    },
    restoreWebsite(slug) {
      this.$confirm({
        message: ` Please confirm to continue Restore of this website. `,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            websiteService.restore(slug).then(res => {
              this.$snotify.success("Website deleted");
              this.loading = false;
              this.$router.push({name: 'dashboard'});
            });

          }
        }
      })

    },
    deleteWebsite(item) {
      let domain = item.domain_name ? item.domain_name : item.sub_domain_name
      this.$confirm({
        message: `Once deleted, website can not be restored. Please confirm to continue deletion of this website. `,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            websiteService.delete(domain).then(res => {
              this.$snotify.success("Website deleted");
              this.getWebsites();
            });
          }
        }
      })

    },
    disconnectWebsite(item) {
      let domain = item.domain_name ? item.domain_name : item.sub_domain_name
      this.$confirm({
        message: `Are you sure`,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            websiteService.disconnectWebsite(domain).then(res => {
              this.$snotify.success("Website deleted");
              this.getWebsites();
            });
          }
        }
      })

    },
    manageSubscription() {
      window.location.href =
          process.env.VUE_APP_SERVER_URL + "my-websites";
    },
  }
};
</script>
